@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Dosis:wght@200;300;400;500;600;700;800&family=Orbitron&family=Pacifico&family=Reem+Kufi:wght@400;500;600;700&family=Signika:wght@300;400;500;600;700&family=Source+Sans+3:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Dosis:wght@200;300;400;500;600;700;800&family=Jost:wght@100;200;300;400;500;600;700;800;900&family=Orbitron&family=Pacifico&family=Reem+Kufi:wght@400;500;600;700&family=Signika:wght@300;400;500;600;700&family=Source+Sans+3:wght@400;600;700&display=swap);
:root {
  --clr-primary-9: #d7f1f4;
  --clr-primary-10: #e6e5fb;
  --clr-primary-indianred: #b04848;
  --clr-primary-orange: #fa6d5a;
  --clr-primary-darkred: #5d5d9c;
  --clr-timeline-cream: #feb0a3;
  --clr-white: #fff;
  --clr-primary-1: #fff;
  --clr-grey-10: whitesmoke;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --clr-black: #000000;
  --max-width: 1170px;
  --fixed-width: 620px;
}

/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif; */
  font-family: "Jost", cursive !important;
  background: var(--clr-white);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #000000;
}

b {
  font-size: 18px;
}

hr {
  border: none;
  border-top: 1px solid #bcccdc;
}

h1,
h2,
h3,
h4,
h5 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  /* margin-bottom: 0.75rem !important; */
}

h5 {
  font-size: 0.875rem;
}

p {
  margin-bottom: 1.25rem;
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1.5rem;
  }

  h5 {
    font-size: 1rem;
  }

  body {
    font-size: 1rem;
  }

  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */

/* section */
.section {
  padding: 5rem 0;
}

.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}

.password_input_modal {
  padding: 0.5rem;
  width: 100%;
  margin-top: 0.4rem;
  border: 2px solid black;
}

.model_sizing {
  width: 350px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

@media screen and (min-width: 992px) {
  .section-center {
    width: 95vw;
  }
}

.text-center {
  text-align: center;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading {
  width: 6rem;
  height: 6rem;
  margin: 0 auto;
  margin-top: 10rem;
  border-radius: 50%;
  border: 4px solid #ccc;
  border-top-color: var();
  animation: spinner 0.6s linear infinite;
}

.btn {
  text-transform: uppercase;
  background: var(--clr-primary-darkred);
  color: var(--clr-white);
  padding: 0.375rem 0.75rem;
  letter-spacing: var(--spacing);
  font-weight: 400;
  transition: var(--transition);
  font-size: 0.875rem;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: var(--radius);
  border-color: transparent;
  font-weight: 600;
  border: 2px solid var(--clr-primary-darkred);
}

.btn:hover {
  color: var(--clr-black) !important;
  font-weight: 600;
  background: var(--clr-white);
  border: 2px solid var(--clr-black);
}

.btn_brand {
  text-transform: uppercase;
  font-size: 0.875rem;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  border: 2px solid black;
  cursor: pointer;
  background: transparent;
  transition: var(--transition);
}

.btn_brand:hover {
  color: var(--clr-black) !important;
  background: black;
  color: white !important;
  border: 2px solid black;
}
.title {
  text-align: center;
  color: #5d5d9c !important;
}
.title h2 {
  color: #5d5d9c !important;
}

.title .underline {
  width: 6rem;
  height: 0.25rem;
  background: #fa6d5a;
  margin-left: auto;
  margin-right: auto;
}

.underline1 {
  width: 6rem;
  height: 0.25rem;
  background: #fa6d5a;
  margin-right: auto;
  margin-top: 0.5rem;
}

.page-100 {
  min-height: calc(100vh - 10rem);
  padding: 5rem 0;
}

.page {
  min-height: calc(100vh - (20vh + 10rem));
}

/* Nav Bar Position fix Css Start */
.scrolled {
  position: fixed !important;
  top: 0 !important;
  background: #fff;
  z-index: 1500;
}

.scrolled a {
  color: var(--clr-black) !important;
  transition: all 0.5s ease;
}

.scrolled .auth-btn {
  color: var(--clr-black) !important;
  transition: all 0.5s ease;
}

.scrolled .cart-btn {
  color: var(--clr-black) !important;
  transition: all 0.5s ease;
}

/* Nav Bar Position fix Css End */

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 456;
}

button.close-button {
  right: 0px;
  position: absolute;
  top: 0px;
  /* background: var(--clr-primary-5); */
  background: var(--clr-primary-indianred);
  border: none;
  box-shadow: none;
  min-width: 40px;
  line-height: 40px;
  color: #fff;
  font-size: 27px;
  cursor: pointer;
}

.MegaMenu__Push .MegaMenu__img {
  display: none !important;
}
/*  */

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  /* color: white; */
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.nav-linkk {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
}

.dropdown-content {
  display: none;
  position: absolute;
  /* left: 25px; */
  background-color: #f9f9f9;
  min-width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 4px 0 4px 0;
  border-radius: 10px;
  /* margin-top: 0.5rem; */
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 7px 12px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  border-radius: 10px;
  color: var(--clr-primary-darkred);
  font-weight: 400;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}
.dropdown:hover .dropdown-content {
  display: block;
}

.zoom_image {
  height: 400px;
  width: 100%;
  object-fit: cover;
}

.nav_icons_flex{
  display: flex;
  align-items: center;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.navbar_inner_menu_flex{
  display: flex;
  align-items: center;
  grid-gap: 0.7rem;
  gap: 0.7rem;
}

.navbar_my_order_menu_resp{
  font-size: 18px;
  font-weight: 600;
  color: #000 !important;
}
@media screen and (max-width: 830px) {
  .zoom_image {
    height: 250px;
  }
}
@media screen and (max-width: 550px) {
  .zoom_image {
    height: 170px;
  }
}
/*  */
@media screen and (max-width: 767px) {
  .title h2,
  .section-title h2 {
    font-size: 23px !important;
  }

  .section-title {
    margin-bottom: 25px !important;
  }
}

.section-centerrrr {
  width: 100%;
  /* height: 85vh; */
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.personn_img_main {
  height: 85vh;
}

.personn-img {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
}

aside h4 {
  text-transform: uppercase;
  color: var(--clr-primary-5);
  margin-bottom: 0.25rem;
}

aside {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;
  transition: var(--transition);
}

aside.activeSlide {
  opacity: 1;
  transform: translateX(0);
}

aside.lastSlide {
  transform: translateX(-100%);
}

aside.nextSlide {
  transform: translateX(100%);
}

@media screen and (max-width: 1008px) {
  .personn_img_main {
    height: 60vh;
  }
}
@media screen and (max-width: 769px) {
  .personn_img_main {
    height: 45vh;
  }
}

@media screen and (max-width: 500px) {
  .section-centerrrr {
    margin-top: 0px;
  }
  aside {
    top: -150px;
  }
}

.timeline_main {
  width: 100%;
  height: 100%;
}

.timeline_con {
  max-width: 1140px;
  margin: 0 auto;
}

.timeline_flex {
  display: flex;
  /* gap: 2rem; */
  justify-content: space-between;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.timeline_first_main {
  max-width: 525px;
  /* min-width: 510px; */
}
.timeline_first {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
}
.timeline_sec {
  /* max-width: 130px;
    min-width: 130px; */
  min-width: 5px;
  background-color: #d6d6d6;
  /* height: 100vh; */
  position: relative;
}

.timeline_third_main {
  /* display: flex;
    flex-direction: column;
    gap: 1rem; */
  max-width: 525px;
  /* min-width: 510px; */
}
.timeline_third {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
}

.timeline_third_inner {
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1.5rem 1rem 1.5rem 1rem;
  height: 130px;
  border-radius: 8px;
}

.timeline_inner_since_flex_align_start {
  align-items: flex-start !important;
}

.timeline_inner_since_flex {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 130px;
}

.timeline_since {
  color: var(--clr-timeline-cream);
  font-weight: 600;
  margin: 0px;
  font-size: 22px;
}

.timeline_step_head {
  margin: 0px;
  color: #7a7a7a;
  font-weight: 500;
  font-size: 16px;
}

.timeline_text {
  font-size: 16px;
  color: #7a7a7a;
  margin-bottom: 0px;
}

.timeline_icon_bg {
  background-color: #d6d6d6;
  font-size: 16px;
  font-weight: 500;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  position: absolute;
  left: -21px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline_icon_bg_sec {
  background-color: var(--clr-timeline-cream);
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline_icon {
  color: var(--clr-white);

  width: 30px;
  height: 30px;
}
.timeline_icon_bg2 {
  background-color: #d6d6d6;
  font-size: 16px;
  font-weight: 500;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  position: absolute;
  left: -21px;
  top: 155px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline_icon_bg_sec2 {
  background-color: var(--clr-timeline-cream);
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline_icon2 {
  color: var(--clr-white);
  width: 30px;
  height: 30px;
}
.timeline_icon_bg3 {
  background-color: #d6d6d6;
  font-size: 16px;
  font-weight: 500;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  position: absolute;
  left: -21px;
  top: 325px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline_icon_bg_sec3 {
  background-color: var(--clr-timeline-cream);
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline_icon3 {
  color: var(--clr-white);
  width: 30px;
  height: 30px;
}

.timeline_icon_bg4 {
  background-color: #d6d6d6;
  font-size: 16px;
  font-weight: 500;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  position: absolute;
  left: -21px;
  top: 485px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline_icon_bg_sec4 {
  background-color: var(--clr-timeline-cream);
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline_icon4 {
  color: var(--clr-white);
  width: 30px;
  height: 30px;
}

.timeline_icon_bg5 {
  background-color: #d6d6d6;
  font-size: 16px;
  font-weight: 500;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  position: absolute;
  left: -21px;
  top: 650px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline_icon_bg_sec5 {
  background-color: var(--clr-timeline-cream);
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline_icon5 {
  color: var(--clr-white);
  width: 30px;
  height: 30px;
}
.timeline_icon_bg6 {
  background-color: #d6d6d6;
  font-size: 16px;
  font-weight: 500;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  position: absolute;
  left: -21px;
  top: 810px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline_icon_bg_sec6 {
  background-color: var(--clr-timeline-cream);
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline_icon6 {
  color: var(--clr-white);
  width: 30px;
  height: 30px;
}
.timeline_icon_bg7 {
  background-color: #d6d6d6;
  font-size: 16px;
  font-weight: 500;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  position: absolute;
  left: -21px;
  top: 975px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline_icon_bg_sec7 {
  background-color: var(--clr-timeline-cream);
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline_icon7 {
  color: var(--clr-white);
  width: 30px;
  height: 30px;
}

.timeline_icon_bg8 {
  background-color: #d6d6d6;
  font-size: 16px;
  font-weight: 500;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  position: absolute;
  left: -21px;
  top: 1135px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline_icon_bg_sec8 {
  background-color: var(--clr-timeline-cream);
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline_icon8 {
  color: var(--clr-white);
  width: 30px;
  height: 30px;
}

.timeline_main_resp {
  display: none;
}

@media screen and (max-width: 1170px) {
  .timeline_first_main {
    max-width: 475px;
  }

  .timeline_third_main {
    max-width: 475px;
  }
}
@media screen and (max-width: 1070px) {
  .timeline_first_main {
    max-width: 100%;
  }

  .timeline_third_main {
    max-width: 100%;
  }

  .timeline_third {
    height: 100%;
  }

  .timeline_flex {
    grid-gap: 2rem;
    gap: 2rem;
  }
}

.timeline_text {
  font-size: 15px;
}

@media screen and (max-width: 1000px) {
  .timeline_con {
    display: none;
  }
  .timeline_main_resp {
    display: block;
    padding-top: 2rem;
    padding-bottom: 4rem;
  }
  .timeline_flex_resp {
    display: flex;
    grid-gap: 3rem;
    gap: 3rem;
  }

  .timeline_flex_part1 {
    min-width: 5px;
    background-color: #d6d6d6;
    /* height: 100vh; */
    position: relative;
  }

  /* .timeline_icon_bg_resp{
        background-color: #d6d6d6;
        font-size: 16px;
        font-weight: 500;
        width: 50px;
        height: 50px;
        border-radius: 50px;
        position: absolute;
        left: -21px;
        top: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        
    } */

  .timeline_icon_bg_resp2 {
    background-color: #d6d6d6;
    font-size: 16px;
    font-weight: 500;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    position: absolute;
    left: -21px;
    top: 260px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .timeline_icon_bg_resp3 {
    background-color: #d6d6d6;
    font-size: 16px;
    font-weight: 500;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    position: absolute;
    left: -21px;
    top: 452px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .timeline_icon_bg_resp4 {
    background-color: #d6d6d6;
    font-size: 16px;
    font-weight: 500;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    position: absolute;
    left: -21px;
    top: 625px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .timeline_icon_bg_sec_resp {
    background-color: var(--clr-timeline-cream);
    width: 40px;
    height: 40px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .timeline_icon_resp {
    color: var(--clr-white);

    width: 30px;
    height: 30px;
  }

  .timeline_flex_part2_inner_resp {
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    width: 100%;
  }

  .timeline_third_inner_resp {
    height: 100% !important;
  }

  .timeline_inner_since_flex_align_start_resp {
    height: 100%;
  }

  .timeline_flex_part2_resp {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
  }
}

